<script>
import {
  CdrText,
  CdrImg,
} from '@rei/cedar';

export default {
  name: 'RecentArticlesCard',
  components: {
    CdrText,
    CdrImg,
  },
  props: {
    card: { type: Object, default: () => {} },
    endCard: { type: Boolean, default: false },
    defaultImage: { type: Object, default: () => {} },
  },
  computed: {
    renderCard() {
      return this.card?.articleTitle && this.card?.uri;
    },
    heroImage() {
      const { heroImage } = this.card;
      return heroImage;
    },
    srcSet() {
      return this.heroImage?.renditions
        .reduce((accumulator, { url, width }) => `${accumulator}${url} ${width}w, `, '')
        .slice(0, -2); // To remove the trailing comma and space.
    },
    fallBackImage() {
      return this.heroImage?.renditions[this.heroImage.renditions.length - 1] || this.defaultImage;
    },
    altText() {
      return this.heroImage ? this.heroImage.altText : this.defaultImage.altText;
    },
  },
};
</script>
<template>
  <a
    v-if="renderCard"
    :href="card.uri"
    :aria-label="card.articleTitle"
    class="recent-articles-card__link"
  >
    <article
      data-ui="article-card"
      class="recent-articles-card__wrapper"
    >
      <cdr-img
        :src="fallBackImage.url"
        :alt="altText || ''"
        :height="fallBackImage.height"
        :width="fallBackImage.width"
        :srcset="srcSet"
        sizes="
          (min-width: 1232px)  368px,
          (min-width: 992px)  283px,
          (min-width: 768px)  30vw,
          calc(100vw - 6.4rem),"
        ratio="4-3"
        cover
      />
      <div class="recent-articles-card__wrapper--content">
        <cdr-text tag="h3">
          {{ card.articleTitle }}
        </cdr-text>
        <hr
          v-if="!endCard"
          class="recent-articles-card__wrapper--line"
        >
      </div>
    </article>
  </a>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.recent-articles-card {
  &__link {
    text-decoration: none;
    &:hover h3 {
      text-decoration: underline;
    }
  }
  &__wrapper {
    color: $cdr-color-text-primary;
    text-decoration: none;
    &--image {
      @include cdr-xs-mq-only {
        display: none;
      }
    }
    & h3 {
      @include cdr-text-heading-serif-500;
      margin-bottom: $cdr-space-one-x;
    }
    &--line {
      display: none;
      @include cdr-xs-mq-only {
        display: block;
        margin-bottom: 0;
      }
    }
    &--content {
      margin-top: $cdr-space-one-x;
    }
    @include cdr-md-mq-down {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 133px;
      & img {
        max-height: 132px;
      }
      &--content {
        padding-left: $cdr-space-one-x;
      }
    }
    @include cdr-xs-mq-only {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      padding: 0;
      margin: 0;
      &--content {
        padding: 0;
      }
    }
  }
}
</style>
