<script>
import {
  CdrImg,
  CdrGrid,
  CdrText,
  CdrLink,
} from '@rei/cedar';
import ImageModal from '../../../common/components/ImageModal.vue';

export default {
  name: 'PhotoGallery',
  components: {
    CdrImg,
    CdrGrid,
    CdrText,
    CdrLink,
    ImageModal,
  },
  props: {
    photos: { type: Array, default: () => [] },
  },
  data() {
    return {
      opened: false,
      modalContent: {
        imageViewUrl: '',
      },
    };
  },
  methods: {
    selectedImage(index) {
      this.opened = true;
      this.modalContent = this.photos[index];
    },
  },
};
</script>
<template>
  <section class="photo-gallery">
    <cdr-grid
      class="photo-gallery__grid--layout"
      gutter="medium"
    >
      <cdr-text
        tag="h2"
        class="photo-gallery__lead--heading"
      >
        Photo Gallery
      </cdr-text>
      <cdr-link
        href="/newsroom/multimedia"
        modifier="standalone"
        class="photo-gallery__lead--link"
      >
        More co-op photos
      </cdr-link>
      <cdr-grid class="photo-gallery__grid--photos">
        <div
          v-for="(photo, i) in photos"
          :key="`recent-photo-${i}`"
        >
          <button
            aria-haspopup="dialog"
            class="photo-gallery__modal-toggle"
            @click="selectedImage(i)"
          >
            <cdr-img
              :src="photo.thumbImage"
              :alt="photo.thumbImageAltText ? photo.thumbImageAltText : ''"
              ratio="4-3"
              cover
              class="photo-gallery__photo"
            />
            <span class="photo-gallery__sr">Enlarge image and see photo details</span>
          </button>
        </div>
      </cdr-grid>
    </cdr-grid>
    <image-modal
      :modal-content="modalContent"
      :opened="opened"
      @closed="opened = false"
    />
  </section>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.photo-gallery {
  width: 100%;
  margin-top: $cdr-space-two-x;
  &__grid {
    &--layout {
      grid-template-columns: repeat(4, 1fr);;
      grid-template-rows: auto;
      grid-template-areas:
        "title . . link"
        "photos photos photos photos";
        @include cdr-xs-mq-only {
          grid-template-columns: 1fr;
          grid-template-rows: 3;
          grid-template-areas:
            "title"
            "photos"
            "link";
        }
    }
    &--photos {
      grid-area: photos;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      @include cdr-md-mq-down {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  &__lead {
     margin: $cdr-space-one-and-a-half-x 0;
    &--heading {
      @include cdr-text-heading-serif-700;
      grid-area: title;
    }
    &--link {
      grid-area: link;
      align-self: center;
      justify-self: end;
      @include cdr-sm-mq-up {
        text-align: right;
      }
    }
  }
  &__modal-toggle {
    background-color: $cdr-color-background-primary;
    border-radius: $cdr-radius-soft;
    transition: transform $cdr-duration-1-x $cdr-timing-function-ease;
    box-shadow: $cdr-prominence-raised;
    font-family: inherit;
    font-size: 100%;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 100%;
  }
  &__modal-toggle:hover {
    -webkit-transform: translateY(-.2rem);
    transform: translateY(-.2rem);
    transition: transform $cdr-duration-1-x $cdr-timing-function-ease;
  }
  &__sr {
    @include cdr-display-sr-only
  }
}
</style>
