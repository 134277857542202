<script>
import { CdrText } from '@rei/cedar';

import FeaturedArticles from './components/FeaturedArticles.vue';
import RecentArticles from './components/RecentArticles.vue';
import PhotoGallery from './components/PhotoGallery.vue';
import initAnalytics from '../../common/utils/analytics';
import { formatCurrentDate } from '../../common/utils/formatDates';

export default {
  name: 'LandingPage',
  components: {
    CdrText,
    FeaturedArticles,
    RecentArticles,
    PhotoGallery,
  },
  props: {
    featuredArticles: { type: Array, default: () => [] },
    recentArticles: { type: Array, default: () => [] },
    recentPhotos: { type: Array, default: () => [] },
  },
  data() {
    return {
      title: 'REI Co-op News',
    };
  },
  computed: {
    currentDate() {
      return formatCurrentDate();
    },
    featuredArticlesCheck() {
      return this.featuredArticles && this.featuredArticles.length === 3;
    },
    recentArticlesCheck() {
      return this.recentArticles && this.recentArticles.length === 3;
    },
    photoGalleryCheck() {
      return this.recentPhotos && this.recentPhotos.length === 8;
    },
  },
  mounted() {
    initAnalytics('#newsroom_landing-page', 'home');
  },
};
</script>

<template>
  <div
    id="newsroom_landing-page"
    class="landing-page"
  >
    <div class="landing-page__lead">
      <cdr-text
        class="landing-page__lead--heading"
        tag="h1"
      >
        {{ title }}
      </cdr-text>
      <cdr-text class="landing-page__lead--date">
        {{ currentDate }}
      </cdr-text>
    </div>
    <featured-articles
      v-if="featuredArticlesCheck"
      id="newsroom_featured-articles"
      :articles="featuredArticles"
      class="landing-page__stack"
    />
    <recent-articles
      v-if="recentArticlesCheck"
      id="newsroom_recent-articles"
      :articles="recentArticles"
      class="landing-page__stack"
    />
    <photo-gallery
      v-if="photoGalleryCheck"
      id="newsroom_photo-gallery"
      :photos="recentPhotos"
      class="landing-page__stack"
    />
  </div>
</template>
<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
.landing-page {
  @include cdr-container;
  margin-bottom: $cdr-space-four-x;
  &__lead {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: $cdr-space-one-and-a-half-x;
    @include cdr-xs-mq-only {
      flex-direction: column;
      justify-content: initial;
    }
    &--heading {
      @include cdr-text-heading-serif-strong-900;
      align-self: flex-start;
      margin-top: $cdr-space-half-x;
      @include cdr-xs-mq-only {
        margin-bottom: $cdr-space-half-x;
      }
    }
    &--date {
      @include cdr-text-utility-sans-300;
      color: $cdr-color-text-secondary;
      align-self: flex-end;
      @include cdr-xs-mq-only {
        align-self: flex-start;
      }
    }
  }
  &__stack {
    padding-top: $cdr-space-one-and-a-half-x;
  }
}
</style>
